import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from "gatsby";
import { BreadcrumbsBasic, BreadcrumbsWithIcons, BreadcrumbsAccessibleOptions, BreadcrumbsBehaviours } from "../../examples/components/Breadcrumbs";
export const query = graphql`
  query BreadcrumbQuery {
    BreadcrumbItem: componentMetadata(displayName: { eq: "BreadcrumbItem" }) {
      ...ComponentApi_metadata
    }
    Breadcrumb: componentMetadata(displayName: { eq: "Breadcrumb" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  Add <code>active</code> prop to active <code>Breadcrumb.Item</code>. Do not
  set both <code>active</code> and <code>href</code> attributes.{" "}
  <code>active</code>
  overrides <code>href</code> and <code>span</code> element is rendered instead of{" "}
  <code>a</code>.
    </Overview>
    <CodeBlock title="Default" code={BreadcrumbsBasic} mdxType="CodeBlock"></CodeBlock>
    <CodeBlock title="Breadcrumbs with Icons" code={BreadcrumbsWithIcons} mdxType="CodeBlock"></CodeBlock>
    <CodeBlock title="Accessible Option" code={BreadcrumbsAccessibleOptions} mdxType="CodeBlock">
  For accessible underlined links add class <code>breadcrumb-underline</code> to{" "}
  <code>Breadcrumb </code>.
    </CodeBlock>
    <CodeBlock title="Behavious" code={BreadcrumbsBehaviours} mdxType="CodeBlock">
  For implementations with large numbers of sub-directories, make use of
  dropdown that appears on click of ellipses as shown in the example below
    </CodeBlock>
    <LinkedHeading h="2" className="h1" id="bd-api" mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.Breadcrumb} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.BreadcrumbItem} exportedBy={props.data.Breadcrumb} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      